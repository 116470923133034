import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { HttpService } from '@app/modules/core/services/http.service';
import { UserService } from '@app/modules/core/services/user.service';

@Injectable()
export class RefreshTokenService {

  constructor(
    private readonly httpService: HttpService,
    private readonly userService: UserService
  ) {
  }

  refreshToken(): Observable<any> {
    return new Observable();
    // const headers = new HttpHeaders({
    //   'Content-type': 'application/x-www-form-urlencoded'
    // });
    //
    // const refreshToken = this.userService.getRefreshToken();
    //
    // const body = {
    //   grant_type: 'refresh_token',
    //   refresh_token: refreshToken
    // }
    //
    // return this.httpService.send(
    //   {
    //     url: 'login',
    //     method: 'POST',
    //     headers,
    //     body
    //   });
  }
}
