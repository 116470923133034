import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';

// Services
import { LoginService } from '@app/modules/core/services/login.service';
import { UserService } from '@app/modules/core/services/user.service';
import { ConfigStorageService } from '@app/modules/core/services/config-storage.service';
import { ConfigResolverService } from '@app/modules/core/services/config-resolver.service';
import { HttpService } from '@app/modules/core/services/http.service';
import { AppInitializerService } from '@app/modules/core/services/app-initializer.service';
import { AuthService } from '@app/modules/core/services/auth.service';
import { RegistrationService } from '@app/modules/core/services/registration.service';
import { RefreshTokenService } from '@app/modules/core/services/refresh-token.service';
import { WebsocketService } from '@app/modules/core/services/websocket.service';

export function initialize(appInitializer: AppInitializerService) {
  return () => appInitializer.init();
}

@NgModule({
  imports: [],
  declarations: [],
  providers: [
    AppInitializerService,
    {
      provide: APP_INITIALIZER,
      useFactory: initialize,
      deps: [AppInitializerService],
      multi: true
    },
    UserService,
    LoginService,
    RegistrationService,
    ConfigStorageService,
    ConfigResolverService,
    HttpService,
    AuthService,
    RefreshTokenService,
    WebsocketService
  ],
})
export class CoreModule {
  /* make sure CoreModule is imported only by one NgModule the AppModule */
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
