import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { catchError } from 'rxjs/operators';

import { SKIP_AUTH_HEADER } from '../../shared/constants/http-headers';

// Services
import { ConfigStorageService } from '@app/modules/core/services/config-storage.service';

@Injectable()
export class ConfigResolverService {
  constructor(
    protected readonly httpClient: HttpClient,
    protected readonly configStorageService: ConfigStorageService
  ) {
  }

  load() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get('config.json', {
          headers: new HttpHeaders({
            [ SKIP_AUTH_HEADER ]: 'true'
          })
        })
        .pipe(
          catchError((error: any): any => {
            console.log('Configuration file "config.json" could not be read');
            reject();
          })
        )
        .subscribe(configResponse => {
          this.configStorageService.store(configResponse);
          resolve();
        });
    });
  }
}
