import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-base-wrapper',
  templateUrl: 'base-wrapper.component.html',
  styleUrls: ['base-wrapper.component.scss']
})

export class BaseWrapperComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}
