import {Component, forwardRef} from '@angular/core';
import {NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'app-custom-video-cover',
  templateUrl: 'custom-video-cover.component.html',
  styleUrls: ['custom-video-cover.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomVideoCoverComponent),
      multi: true
    }
  ]
})

export class CustomVideoCoverComponent {
  public imagePath;
  imgURL: any;
  public message: string;

  constructor() {
  }

  // Установить выбранное значение
  private setSelectedValue(v: any): void {
    // console.log(v);
    // if (this.itemsList) {
    //   let value: SelectComponentItemModel;
    //   try {
    //     value = this.itemsList
    //       .find((item: SelectComponentItemModel) => item.value === v);
    //   } catch (err) {
    //     value = new SelectComponentItemModel();
    //     value.value = '';
    //     value.title = 'ошибка';
    //     this.isDisabled = true;
    //   }
    //
    //   this.selectedTitle = value ? value.title : '';
    //   this.selectedValue = value;

    // this.propagateChange(value.value);
    this.propagateChange(v);
    // }
  }

  public preview(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      console.warn("Only images are supported.");
      return;
    }

    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }

    // console.log(files);

    this.setSelectedValue(files);
  }


  /** Связываем через ngModel */
  public writeValue(value: any): void {
    // console.log(value);
    if (!value) {
      // this.clearSelected();

      return;
    }

    // this.selectedValue = value;
    //
    // if (!this.loading) {
    this.setSelectedValue(value);
    // }
  }

  /** Выбор значения селекта */
  public selectValue(value: any): void {
    this.setSelectedValue(value);
  }

  // Метод интерфейса ControlValueAccessor
  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  // Метод интерфейса ControlValueAccessor
  // tslint:disable-next-line:no-empty
  public registerOnTouched(fn: any): void {
  }

  // Колбэк на value change
  // tslint:disable-next-line:no-empty
  public propagateChange = (fn: any): void => {
  };
}
