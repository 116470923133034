<div class="control">
  <label *ngIf="!!label"
         [attr.for]="label"
         class="control__label"
  >
    {{label}}
  </label>

  <input #input
         class="control__input"
         [ngClass]="{'control__input--error': !control.pristine && !control.valid}"
         id="{{label}}"
         type="{{type}}"
         placeholder="{{placeholder}}"
         (blur)="onChange($event, input.value)"
  >
</div>
