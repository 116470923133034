import { Injectable } from '@angular/core';

// RxJs
import { Observable } from 'rxjs';

// Services
import { HttpService } from '@app/modules/core/services/http.service';
import { ConfigStorageService } from '@app/modules/core/services/config-storage.service';

// Interfaces
import { SignUpForm } from '@app/modules/shared/interfaces/sign-up-form';
import { SignUpBtForm } from '@app/modules/shared/interfaces/sign-up-bt-form';

@Injectable()
export class RegistrationService {
  private readonly httpUrlBt: string;

  constructor(
    private readonly httpService: HttpService,
    private readonly configStorageService: ConfigStorageService,
  ) {
    this.httpUrlBt = this.configStorageService.get('httpURLBT');
  }

  register(formData: SignUpForm): Observable<any> {
    const body = formData;

    return this.httpService.send(
      {
        url: 'register',
        method: 'POST',
        body
      });
  }

  registerBT(formData: SignUpBtForm): Observable<any> {
    const body = formData;

    return this.httpService.send(
      {
        url: 'auth/register',
        method: 'POST',
        host: this.httpUrlBt,
        body
      });
  }
}
