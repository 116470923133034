import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bigNumber'
})

export class BigNumberPipe implements PipeTransform {
  transform(value: number, ...args: any[]): any {
    let result = value ? value.toString() : '';

    if (result && result.length > 17) {
      result = result.slice(0, result.length - 15) + 'q';
    } else if (result && result.length > 14) {
      result = result.slice(0, result.length - 12) + 't';
    } else if (result && result.length > 11) {
      result = result.slice(0, result.length - 9) + 'b';
    } else if (result && result.length > 8) {
      result = result.slice(0, result.length - 6) + 'm';
    } else if (result && result.length > 5) {
      result = result.slice(0, result.length - 3) + 'k';
    }

    return result;
  }
}
