import { Injectable } from '@angular/core';

// RxJs
import { BehaviorSubject, Subscription } from 'rxjs';

// Services
import { ConfigStorageService } from '@app/modules/core/services/config-storage.service';
import { AuthService } from '@app/modules/core/services/auth.service';

const RECONNECT_INTERVAL = 5000;

@Injectable()
export class WebsocketService {
  private connection;

  private readonly receaveMessageOptions = new BehaviorSubject<any>(null);

  constructor(
    private readonly configResolver: ConfigStorageService,
    private readonly authService: AuthService
  ) {
  }

  connect() {
    return new Promise((resolve, reject) => {
      const token = this.authService.getToken();

      if (token) {
        this.connection = new WebSocket(`${this.configResolver.get('websocketURL')}?token=${token}`);

        this.connection.onopen = () => {
          console.warn("ws connect");
          resolve();
        };

        this.connection.onclose = () => {
          reject();
          setTimeout(() => {
            this.connect();
          }, RECONNECT_INTERVAL);
        };

        this.connection.onmessage = message => {
          const data = JSON.parse(message.data);
          this.receaveMessageOptions.next(data);
        };
      } else {
        resolve();
        setTimeout(() => {
          this.connect();
        }, RECONNECT_INTERVAL);
      }
    });
  }

  public onReceave(then: (opt: any) => void): Subscription {
    return this.receaveMessageOptions.subscribe(then);
  }
}
