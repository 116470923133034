<footer class="footer">
  <div class="footer__container">
    <div class="footer__copyright">
      © BrokerTraffic.com 2022
    </div>

    <nav class="footer__links">
      <ul class="footer__links-list">
        <li>
          <a routerLink="/terms">
            Terms & Conditions
          </a>
        </li>
<!--        <li>-->
<!--          <a (click)="userContactUs()">-->
<!--            Contact-->
<!--          </a>-->
<!--        </li>-->
      </ul>
    </nav>
  </div>
</footer>
