import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// Interfaces
import { WebmasterEditForm } from '@app/modules/shared/interfaces/webmaster-edit-form';

// Services
import { SelfService } from '@app/modules/shared/services';
import { UserService } from '@app/modules/core';

// Constants
import { MIN_PAYOUT } from '@app/modules/shared/constants/min-payout';
import { PAYMENT_METHODS } from '@app/modules/shared/constants/payment-methods';

// Models
import { WebmasterModel } from '@app/modules/admin-webmasters/models/webmaster-model';

@Component({
  selector: 'app-user-edit',
  templateUrl: 'user-edit.component.html',
  styleUrls: ['user-edit.component.scss']
})

export class UserEditComponent implements OnInit {
  public form: FormGroup;
  public error: string;

  isPassword = true;

  webmaster: WebmasterModel;

  constructor(
    private readonly fb: FormBuilder,
    private readonly activeModal: NgbActiveModal,
    private readonly selfService: SelfService,
    private readonly userService: UserService
  ) {
  }

  getSelfInfo(): void {
    this.selfService.getUserSelf()
      .subscribe((res) => {
        this.webmaster = res[0];

        this.initForm();
      })
  }

  private initForm(): void {
    this.form = this.fb.group({
      // avatar: [this.webmaster && this.webmaster.avatar || ''],
      email: [this.webmaster.email || '', [Validators.email, Validators.required]],
      password: [''],
      mainSite: [this.webmaster.mainSite || ''],
      skype: [this.webmaster.skype || ''],
      telegram: [this.webmaster.telegram || ''],
    });

    this.form.valueChanges.subscribe(() => {
      this.resetError();
    })
  }

  resetError(): void {
    if (!!this.error) {
      this.error = '';
    }
  }

  passwordViewToggle(): void {
    this.isPassword = !this.isPassword;
  }

  onSubmit({ value, valid }: { value: WebmasterEditForm, valid: boolean }): void {
    if (valid) {
      const formData = new FormData();
      const config = {
        skype: value.skype,
        telegram: value.telegram,
        email: value.email,
        mainSite: value.mainSite,
        username: this.webmaster.username,
        identityId: this.webmaster.identityId,
        passwordHash: value.password.trim()
      };

      if (value.avatar) {
        config[ 'avatar' ] = this.webmaster && this.webmaster.avatar ? this.webmaster.avatar : '';

        const avatarFile: File = (typeof value.avatar === 'string') ? null : value.avatar[ 0 ];
        if (avatarFile) {
          formData.append('avatar', avatarFile, avatarFile.name);
        }
      } else {
        formData.append('avatar', null);
      }

      formData.append('config', JSON.stringify(config));

      this.selfService.setUserSelf(formData)
        .subscribe(
          (res) => {
            this.userService.setUserAvatar(res.data[0].avatar);
            this.activeModal.close();
          },
          (err) => {
            this.error = err.error;
          })
    }
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }

  ngOnInit(): void {
    this.getSelfInfo();
  }
}
