<div class="upload-progress"
     [ngClass]="{
				'upload-progress--visible': isShow
			}">
  <div class="upload-progress__title">
    Uploaded video status.
  </div>
  <div class="upload-progress__list" *ngIf="stack">
    <div class="upload-progress__item" *ngFor="let item of stack">
      <div class="upload-progress__name">
        {{ item.fileName }}
      </div>
      <div class="upload-progress__cancel" (click)="cancel(item.guid)">
        <app-icon icon="close-alt"></app-icon>
      </div>
      <div class="upload-progress__bar">
        <div class="upload-progress__percent">
          {{ item.loaded ? (item.loaded | mBites) : 0 }} of {{ item.total ? (item.total | mBites) : 0 }}
          ({{item.percent ? item.percent : 0}}%)
        </div>
        <div class="upload__current" [ngStyle]="{'width.%': item.percent ? item.percent : 0}"></div>
      </div>
    </div>
  </div>
</div>
