<div class="loader-container">
  <div
    class="loader"
    *ngIf="isLoading"
    (click)="onClick($event)"
  >
    <img src="/assets/spinner.gif" />
  </div>

  <ng-content>
  </ng-content>
</div>
