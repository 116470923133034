import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// Interfaces
import { SignInForm } from '@app/modules/shared/interfaces/sign-in-form';

// Services
import { AuthService } from '@app/modules/core/services/auth.service';
import { LoginService } from '@app/modules/core/services/login.service';

// Components
import {
  ConfirmRestorePasswordComponent
} from '@app/modules/shared/components/modals/confirm-restore-password/confirm-restore-password.component';
import { ConfigStorageService } from '@app/modules/core/services/config-storage.service';

@Component({
  selector: 'app-restore-password',
  templateUrl: 'restore-password.component.html',
  styleUrls: ['restore-password.component.scss']
})

export class RestorePasswordComponent implements OnInit {
  public form: FormGroup;
  public error: string;
  public captchaKey: string;

  constructor(
    private readonly fb: FormBuilder,
    private readonly activeModal: NgbActiveModal,
    private readonly loginService: LoginService,
    private readonly authService: AuthService,
    private readonly modalService: NgbModal,
    private readonly configStorageService: ConfigStorageService,
  ) {
    this.captchaKey = this.configStorageService.get('recaptchaKey');
  }

  private initForm(): void {
    this.form = this.fb.group({
      email: ['', [Validators.email, Validators.required]],
      recaptchaToken: ['', Validators.required]
    });

    this.form.valueChanges.subscribe(() => {
      this.resetError();
    })
  }

  resetError(): void {
    if (!!this.error) {
      this.error = '';
    }
  }

  onSubmit({ value, valid }: { value: SignInForm, valid: boolean }): void {
    if (valid) {
      const request = this.loginService.restorePassword(this.form.getRawValue());

      request
        .subscribe({
          next: () => {
            this.activeModal.close();
            this.modalService.open(ConfirmRestorePasswordComponent, {
              keyboard: true,
              size: '420',
              backdrop: true,
              centered: true
            })
          },
          error: (err) => {
            window['grecaptcha'].reset();
            this.form.controls.recaptchaToken.setValue('');
            this.error = err.error;
          }
        });
    }
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }

  ngOnInit(): void {
    this.initForm();
  }
}
