import { Injectable } from '@angular/core';

@Injectable()
export class ConfigStorageService {
  public config: Object = null;

  store(obj: Object): void {
    this.config = obj;
  }

  get(key: string): any {
    return this.config && this.config[ key ];
  }
}
