import { Injectable } from '@angular/core';
import { plainToClass } from "class-transformer";

// Services
import { HttpService } from "@app/modules/core";

// RxJs
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

// Models
import { ChannelModel } from "@app/modules/shared/models/channel.model";
import { ChannelStatModel } from '@app/modules/main/models/channel-stat-model';

@Injectable()
export class ChannelsService {
  constructor(
    private readonly httpService: HttpService
  ) {
  }

  /**
   * Get all channels list
   */
  getUserChannelsList(): Observable<any> {
    return this.httpService.send(
      {
        url: `channels`,
        method: 'GET'
      })
      .pipe(
        map(res => plainToClass(ChannelModel, res.data))
      );
  }

  /**
   * Create channel
   * @param {ChannelModel} data
   */
  createChannel(data: FormData): Observable<any> {
    return this.httpService.multi(
      {
        url: 'channels',
        method: 'POST',
        body: data
      });
  }

  /**
   * Delete channel
   * @param {string} id
   */
  deleteChannel(id: string): Observable<any> {
    return this.httpService.send(
      {
        url: `channels/${id}`,
        method: 'DELETE'
      });
  }

  /**
   * Delete channel and videos
   * @param {string} id
   */
  deleteChannelAndVideos(id: string): Observable<any> {
    return this.httpService.send(
      {
        url: `channels/${id}?video=true`,
        method: 'DELETE'
      });
  }

  /**
   * Get channel info
   * @param {string} id
   */
  getChannel(id: string): Observable<any> {
    return this.httpService.send(
      {
        url: `channels/${id}`,
        method: 'GET'
      })
      .pipe(
        map(res => plainToClass(ChannelModel, res.data[ 0 ]))
      );
  }

  /**
   * Update channel data
   * @param {string} id
   * @param {FormData} formData
   */
  updateChannel(id: string, formData: FormData): Observable<any> {
    return this.httpService.multi(
      {
        url: `channels/${id}`,
        method: 'PUT',
        body: formData
      });
  }

  /**
   * Delete all channel images if any exist
   * @param {string} id
   */
  deleteChannelImages(id: string): Observable<any> {
    return this.httpService.send(
      {
        url: `channels/${id}/images`,
        method: 'DELETE'
      });
  }

  /**
   * Get channels stats
   */
  getChannelsStats(): Observable<any> {
    return this.httpService.send(
      {
        url: `channels/stats`,
        method: 'GET'
      })
      .pipe(
        map(res => plainToClass(ChannelStatModel, res.data))
      )
  }

  /**
   * Set channel new owner
   * @param {string} id
   * @param {Object} data
   */
  setChannelOwner(id: string, data: Object): Observable<any> {
    return this.httpService.send(
      {
        url: `channels/${id}/assign`,
        method: 'PUT',
        body: data
      });
  }

  getWebmasterChannel(id: string): Observable<any> {
    return this.httpService.send(
      {
        url: `channels/user/${id}`,
        method: 'GET'
      })
      .pipe(
        map(res => plainToClass(ChannelModel, res.data))
      );
  }
}
