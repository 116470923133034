import { Injectable } from '@angular/core';

// RxJs
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

// Models
import { NicheModel } from '@app/modules/shared/models/niche.model';

// Services
import { NichesService } from '@app/modules/shared/services/niches.service';

@Injectable()
export class NichesStorageService {
  private nichesList: NicheModel[] = [];

  constructor(
    private readonly nichesService: NichesService
  ) {
  }

  public loadNichesList(): Observable<any> {
    let subj = new Subject();

    this.nichesService.getNiches()
      .pipe(
        finalize(() => subj.complete())
      )
      .subscribe(
        (res) => {
          this.nichesList = res;
          subj.next(this.nichesList);
        },
        () => {
          subj.next();
        }
      )

    return subj;
  }

  getNichesList(): NicheModel[] {
    return this.nichesList;
  }
}
