import { Injectable } from '@angular/core';
import { HttpService } from '@app/modules/core';
import { plainToClass } from 'class-transformer';

// RxJs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Models
import { NicheModel } from '@app/modules/shared/models/niche.model';
import { CategoryModel } from '@app/modules/shared/models/category.model';

@Injectable()
export class NichesService {
  constructor(
    private readonly httpService: HttpService
  ) {
  }

  /**
   * Get all niches
   */
  getNiches(): Observable<any> {
    return this.httpService.send(
      {
        url: 'niches',
        method: 'GET',
      })
      .pipe(
        map((res) => plainToClass(NicheModel, res.data))
      );
  }

  /**
   * Get niche categories
   * @param {string} id
   */
  getNicheCategories(id: string): Observable<any> {
    return this.httpService.send(
      {
        url: `niches/${id}/categories`,
        method: 'GET',
      })
      .pipe(
        map((res) => plainToClass(CategoryModel, res.data))
      );
  }
}
