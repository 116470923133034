import { ChannelImageModel } from '@app/modules/shared/models/channel-image.model';

export class ChannelModel {
  advertisingLink: string;
  advertisingText: string;
  allowExternalAdvertising: boolean;
  appearancesNumber: number;
  avatar: ChannelImageModel;
  description: string;
  desktopBanner: ChannelImageModel;
  id: string;
  linkOnPlayer: string;
  mobileBanner: ChannelImageModel;
  name: string;
  networkGUID: string;
  nicheGUID: string;
  tags: string[];
  textOnPlayer: string;
  url: string;
  videosCount: number;
}
