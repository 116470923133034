<div class="upload-video">
  <div class="upload-video__preview" *ngIf="videoURL">
<!--    <video [muted]="true"-->
<!--           autoplay>-->
<!--      <source [src]="videoURL">-->
<!--      Your browser does not support the video tag.-->
<!--    </video>-->

    <span>
      {{ isFileUploaded ? ('Filename: ' + videoTitle + ' was succesfully uploaded') : ('Filename: ' + videoTitle ) }}
    </span>
  </div>

  <div class="upload-video__control">
    <label class="upload-video__button">
      <input type="file"
             #file
             (change)="preview(file.files)"
             accept='video/*'
      />
      <span class="upload-video__button-text">
        {{ videoURL ? 'Reload video' : 'Load video' }}
      </span>
    </label>
  </div>
</div>
