import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Guards
import { IsAnonGuard } from "@app/modules/shared/guards/is-anon-guard";
import { IsAuthGuard } from "@app/modules/shared/guards/is-auth-guard";
import { IsAdminGuard } from "@app/modules/shared/guards/is-admin-guard";

const routes: Routes = [
  {
    path: '',
    redirectTo: '/main',
    pathMatch: 'full'
  },
  {
    path: 'main',
    loadChildren: () => import('./modules/main/main.module').then(mod => mod.MainModule),
    canLoad: [
      IsAuthGuard
    ],
    canActivate: [
      IsAuthGuard
    ],
    canActivateChild: [
      IsAuthGuard
    ]
  },
  {
    path: 'channels',
    loadChildren: () => import('./modules/channels/channels.module').then(mod => mod.ChannelsModule),
    canLoad: [
      IsAuthGuard
    ],
    canActivate: [
      IsAuthGuard
    ],
    canActivateChild: [
      IsAuthGuard
    ]
  },
  {
    path: 'videos',
    loadChildren: () => import('./modules/videos/videos.module').then(mod => mod.VideosModule),
    canLoad: [
      IsAuthGuard
    ],
    canActivate: [
      IsAuthGuard
    ],
    canActivateChild: [
      IsAuthGuard
    ]
  },
  {
    path: 'feeds',
    loadChildren: () => import('./modules/feeds/feeds.module').then(mod => mod.FeedsModule),
    canLoad: [
      IsAuthGuard
    ],
    canActivate: [
      IsAuthGuard
    ],
    canActivateChild: [
      IsAuthGuard
    ]
  },
  // {
  //   path: 'change-password',
  //   loadChildren: () => import('./modules/change-password/change-password.module').then(mod => mod.ChangePasswordModule)
  // },
  {
    path: 'terms',
    loadChildren: () => import('./modules/terms/terms.module').then(mod => mod.TermsModule)
  },
  {
    path: 'promo',
    loadChildren: () => import('./modules/promo/promo.module').then(mod => mod.PromoModule),
    canLoad: [
      IsAnonGuard
    ],
    canActivate: [
      IsAnonGuard
    ],
    canActivateChild: [
      IsAnonGuard
    ]
  },
  {
    path: 'admin-webmasters',
    loadChildren: () => import('./modules/admin-webmasters/admin-webmasters.module').then(mod => mod.AdminWebmastersModule),
    canLoad: [
      IsAuthGuard,
      IsAdminGuard
    ],
    canActivate: [
      IsAuthGuard,
      IsAdminGuard
    ],
    canActivateChild: [
      IsAuthGuard,
      IsAdminGuard
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
