<div class="app">
  <app-header></app-header>

  <main>
    <router-outlet></router-outlet>
  </main>

  <app-footer></app-footer>

  <app-upload-progress></app-upload-progress>
  <app-notification></app-notification>
</div>
