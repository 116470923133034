import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot
} from '@angular/router';

// RxJs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { NichesStorageService } from '@app/modules/shared/services';

@Injectable()
export class LoadNichesGuard implements CanActivate {
  constructor(
    private readonly nichesStorageService: NichesStorageService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.nichesStorageService.loadNichesList()
      .pipe(
        map(() => {
          return true;
        })
      )
  }
}
