import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpEventType } from '@angular/common/http';

// RxJs
import { Subscription } from 'rxjs';

// Interfaces
import { IUploadProgress } from '@app/modules/shared/interfaces/upload-progress.interface';
import { IUploadFile } from '@app/modules/shared/interfaces/upload-file.interface';

// Services
import { NotificationService, UploadProgressService, VideosService } from '@app/modules/shared/services';
import { UploadFileModel } from '@app/modules/shared/models/upload-file.model';

// Enums
import { NotificationStatusEnum } from '@app/modules/shared/enums';

@Component({
  selector: 'app-upload-progress',
  templateUrl: 'upload-progress.component.html',
  styleUrls: ['upload-progress.component.scss']
})

export class UploadProgressComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];

  public isShow = false;

  public options: IUploadProgress;

  public percent: number;

  public stack: UploadFileModel[] = [];

  public requests = {};

  constructor(
    private readonly uploadProgressService: UploadProgressService,
    private readonly videosService: VideosService,
    private readonly notifyService: NotificationService,
  ) {
  }

  private addInStack(i): void {
    if (i) {
      const index = this.stack.findIndex((item) => item.guid === i.guid);
      if (index >= 0) {
        this.stopRequest(i.guid);
        this.stack.splice(index, 1);
      }

      this.stack.push(i);
    }

    if (this.stack.length) {
      this.isShow = true;

      this.stack.forEach((item) => {
        if (!item.uploadNow) {
          this.requests[ item.guid ] = this.videosService.uploadVideo(item.guid, item.formData)
            .subscribe(
              (resp) => {
                item[ 'uploadNow' ] = true;

                if (resp.type === HttpEventType.Response) {
                  item[ 'uploadNow' ] = false;
                  this.notifyService.show({
                    status: NotificationStatusEnum.Success,
                    text: `«${item.fileName}» Video file uploaded success`,
                    guid: item.guid
                  });
                }
                if (resp.type === HttpEventType.UploadProgress) {
                  item[ 'loaded' ] = resp.loaded;
                  item[ 'total' ] = resp.total;
                  item[ 'percent' ] = Math.round(100 * resp.loaded / resp.total);

                  if (resp.loaded === resp.total) {
                    this.removeFromStack(item.guid);
                    this.hide();
                  }
                }
              },
              (err) => {
                this.notifyService.show({
                  status: NotificationStatusEnum.Error,
                  text: `«${item.fileName}» Video file upload error`
                });
                console.log(err);
              }
            )
        }
      })
    }
  }

  private initSubscriptions(): void {
    this.subs = [
      this.uploadProgressService
        .onOpen((options: IUploadProgress) => {
          if (options) {
            this.isShow = true;
            this.options = options;

            this.percent = Math.round(100 * options.loaded / options.total);
          }
        }),
      this.uploadProgressService
        .onHide(() => {
          this.isShow = false;
        }),
      this.uploadProgressService
        .onUpload((uploadFile: IUploadFile) => {
          this.addInStack(uploadFile);
        })
    ];
  }

  private stopRequest(guid): void {
    this.requests[ guid ].unsubscribe();
  }

  public cancel(guid: string): void {
    this.removeFromStack(guid);
    this.stopRequest(guid);
    this.hide();
  }

  private removeFromStack(guid: string): void {
    const index = this.stack.findIndex((item) => item.guid === guid);
    this.stack.splice(index, 1);
  }

  private hide(): void {
    if (!this.stack.length) {
      this.isShow = false;
    }
  }

  public ngOnInit() {
    this.initSubscriptions();
  }

  public ngOnDestroy(): void {
    this.subs.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
