<div class="popup">
  <div class="popup__header">
    Recovery link has been sent to your email

    <div class="popup__close"
         (click)="closeModal()"
    >
      <app-icon icon="close"></app-icon>
    </div>
  </div>

  <div class="popup__footer">
    <div class="popup__buttons">
      <button type="button"
              class="popup__alt"
              (click)="closeModal()"
      >
        Ok
      </button>
    </div>
  </div>
</div>
