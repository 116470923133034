// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';

// RxJs
import { Subscription } from 'rxjs';

// Interfaces
import { INotificationOptions } from '@app/modules/shared/interfaces/notification-options.interface';

// Services
import { NotificationService } from '@app/modules/shared/services';

// Enums
import { NotificationStatusEnum } from '@app/modules/shared/enums';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];

  // Отображать окно оповещения
  public isShow = false;

  // Параметры отображения оповещения
  public options: INotificationOptions;

  // Статусы отображения оповещения
  public statuses = NotificationStatusEnum;

  constructor(private readonly notificationService: NotificationService) {
  }

  // Инициализация подписок
  private initSubscriptions(): void {
    this.subs = [
      this.notificationService
        .onOpen((options: INotificationOptions) => {
          if (options) {
            this.isShow = true;
            this.options = options;
          }
        }),
      this.notificationService
        .onHide(() => {
          this.isShow = false;
        })
    ];
  }

  // Закрыть оповещение
  public close(): void {
    this.notificationService.hide();
  }

  public ngOnInit(): void {
    this.initSubscriptions();
  }

  public ngOnDestroy(): void {
    this.subs.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
