<div class="popup">
  <div class="popup__header">
    Edit

    <div class="popup__close"
         (click)="closeModal()"
    >
      <app-icon icon="close"></app-icon>
    </div>
  </div>


  <form novalidate
        (ngSubmit)="onSubmit(form)"
        [formGroup]="form"
        *ngIf="form"
  >
    <div class="popup__body">
<!--      <div class="popup__field">-->
<!--        <div class="control">-->
<!--          <label for="login" class="control__label">-->
<!--            Username-->
<!--          </label>-->
<!--          <input id="login" class="control__input" type="text" formControlName="login"-->
<!--                 [ngClass]="{'control__input&#45;&#45;error': !form.pristine && !form.controls.login.valid}"-->
<!--          >-->
<!--        </div>-->
<!--      </div>-->

      <div class="popup__field">
        <div class="control">
          <label for="email" class="control__label">
            Email <span>({{webmaster.verifiedEmail ? 'Verified' : 'Not verified'}})</span>
          </label>
          <input id="email" class="control__input" type="email" formControlName="email"
                 [ngClass]="{'control__input--error': !form.pristine && !form.controls.email.valid}"
          >
        </div>
      </div>

      <div class="popup__field">
        <div class="control">
          <label for="password" class="control__label">
            Password
          </label>
          <input id="password" class="control__input" [type]="isPassword ? 'password' : 'text'"
                 formControlName="password"
                 [ngClass]="{'control__input--error': !form.pristine && !form.controls.password.valid}"
          >
          <div class="control__icon" (click)="passwordViewToggle()">
            <app-icon icon="eye"></app-icon>
          </div>
        </div>
      </div>

<!--      <div class="popup__field">-->
<!--        <div class="control">-->
<!--          <label class="control__label">-->
<!--            Avatar-->
<!--          </label>-->
<!--          <app-upload-image [formControl]="form.controls['avatar']"-->
<!--                            [width]="100"-->
<!--                            [height]="100"-->
<!--          ></app-upload-image>-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <div class="popup__body">
      <div class="popup__field">
        <div class="control">
          <label for="mainSite" class="control__label">
            Main site
          </label>
          <input id="mainSite" class="control__input" type="text" formControlName="mainSite">
        </div>
      </div>

      <div class="popup__field popup__field--half">
        <div class="control">
          <label for="skype" class="control__label">
            Skype (optional)
          </label>
          <input id="skype" class="control__input" type="text" formControlName="skype">
        </div>
      </div>

      <div class="popup__field popup__field--half">
        <div class="control">
          <label for="telegram" class="control__label">
            Telegram (optional)
          </label>
          <input id="telegram" class="control__input" type="text" formControlName="telegram">
        </div>
      </div>

<!--      <div class="popup__field popup__field&#45;&#45;half">-->
<!--        <div class="control">-->
<!--          <label class="control__label">-->
<!--            Payment method-->
<!--          </label>-->
<!--          <app-select-->
<!--            placeholder="Select method"-->
<!--            [itemsList]="PAYMENT_METHODS"-->
<!--            formControlName="paymentType"-->
<!--          ></app-select>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="popup__field popup__field&#45;&#45;half">-->
<!--        <div class="control">-->
<!--          <label class="control__label">-->
<!--            Min payout-->
<!--          </label>-->
<!--          <app-select-->
<!--            placeholder="Min payout"-->
<!--            [itemsList]="MIN_PAYOUT"-->
<!--            formControlName="minPayment"-->
<!--          ></app-select>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="popup__field" *ngIf="form.controls.paymentType.value === 'paxum'">-->
<!--        <div class="control">-->
<!--          <label for="paxumEmail" class="control__label">-->
<!--            Paxum email-->
<!--          </label>-->
<!--          <input id="paxumEmail" class="control__input" type="email" formControlName="paxumEmail"-->
<!--                 [ngClass]="{'control__input&#45;&#45;error': !form.pristine && !form.controls.paxumEmail.valid}">-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <div class="popup__footer">

<!--      <re-captcha formControlName="recaptchaToken" [siteKey]="captchaKey"></re-captcha>-->

      <div class="popup__error" *ngIf="!!error">
        Error: <span>{{ error }}</span>
      </div>

      <div class="popup__buttons">
        <button type="submit"
                class="popup__submit"
                [disabled]="!form.valid"
        >
          Save settings
        </button>

        <button type="button"
                class="popup__alt"
                (click)="closeModal()"
        >
          Cancel
        </button>
      </div>

<!--      <p class="popup__footer-text">-->
<!--        By clicking sign up button you are agreeing with the <a (click)="navigate('/terms')">Terms</a>-->
<!--      </p>-->
    </div>
  </form>

</div>
