<div class="popup">
  <app-loading-indicator [isLoading]="isLoading">
    <div class="popup__header">
      Sign in

      <div class="popup__close"
           (click)="closeModal()"
      >
        <app-icon icon="close"></app-icon>
      </div>
    </div>

    <div class="popup__tabs">
      <div class="popup__tab"
           [ngClass]="{ 'popup__tab--active': currentSource === SOURCES.cp }"
           (click)="setCurrentSource('cp')"
      >
        Content program
      </div>
      <div class="popup__tab"
           [ngClass]="{ 'popup__tab--active': currentSource === SOURCES.bt }"
           (click)="setCurrentSource('bt')"
      >
        Partnership program
      </div>
    </div>

    <form novalidate
          (ngSubmit)="onSubmit(form)"
          [formGroup]="form"
    >
      <div class="popup__body">
        <div class="popup__field">
          <div class="control">
            <label for="username" class="control__label">
              Login
            </label>
            <input id="username" class="control__input" type="text" formControlName="username"
                   [ngClass]="{'control__input--error': !form.pristine && !form.controls.username.valid}"
            >
          </div>
        </div>

        <div class="popup__field">
          <div class="control">
            <label for="password_hash" class="control__label">
              Password
            </label>
            <input id="password_hash" class="control__input" [type]="isPassword ? 'password' : 'text'"
                   formControlName="password_hash"
                   [ngClass]="{'control__input--error': !form.pristine && !form.controls.password_hash.valid}"
            >
            <div class="control__icon" (click)="passwordViewToggle()">
              <app-icon icon="eye"></app-icon>
            </div>
          </div>
        </div>
      </div>

      <div class="popup__footer">
        <div class="popup__error" *ngIf="!!error">
          Error: <span>{{ error }}</span>
        </div>

        <div class="popup__buttons">
          <button type="submit"
                  class="popup__submit"
                  [disabled]="!form.valid"
          >
            Sign in
          </button>

          <button type="button"
                  class="popup__alt"
                  (click)="restorePassword()"
          >
            Forgot password?
          </button>
        </div>

      </div>
    </form>

  </app-loading-indicator>
</div>
