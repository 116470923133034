<div class="popup">
  <app-loading-indicator [isLoading]="isLoading">
    <div class="popup__header">
      {{ isAdmin ? 'Webmaster registration' : 'Sign up' }}

      <div class="popup__close"
           (click)="closeModal()"
      >
        <app-icon icon="close"></app-icon>
      </div>
    </div>

    <div class="popup__tabs" *ngIf="!isAdmin">
      <div class="popup__tab"
           [ngClass]="{ 'popup__tab--active': currentSource === SOURCES.cp }"
           (click)="setCurrentSource('cp')"
      >
        Content program
      </div>
      <div class="popup__tab"
           [ngClass]="{ 'popup__tab--active': currentSource === SOURCES.bt }"
           (click)="setCurrentSource('bt')"
      >
        Partnership program
      </div>
    </div>

    <div class="popup__subtitle"
         *ngIf="currentSource === SOURCES.cp && !isAdmin"
    >
      You have exclusive content you want to publish on our websites
    </div>
    <div class="popup__subtitle"
         *ngIf="currentSource === SOURCES.bt && !isAdmin"
    >
      You have adult traffic you want to sell to us
    </div>

    <form novalidate
          (ngSubmit)="onSubmit(form)"
          [formGroup]="form"
          *ngIf="currentSource === SOURCES.cp"
    >
      <div class="popup__body">
        <div class="popup__field">
          <div class="control">
            <label for="username" class="control__label">
              Username
            </label>
            <input id="username" class="control__input" type="text" formControlName="username"
                   [ngClass]="{'control__input--error': !form.pristine && !form.controls.username.valid}"
            >
          </div>
        </div>

        <div class="popup__field">
          <div class="control">
            <label for="email" class="control__label">
              Email
            </label>
            <input id="email" class="control__input" type="email" formControlName="email"
                   [ngClass]="{'control__input--error': !form.pristine && !form.controls.email.valid}"
            >
          </div>
        </div>

        <div class="popup__field">
          <div class="control">
            <label for="password_hash" class="control__label">
              Password
            </label>
            <input id="password_hash" class="control__input" [type]="isPassword ? 'password' : 'text'"
                   formControlName="password_hash"
                   [ngClass]="{'control__input--error': !form.pristine && !form.controls.password_hash.valid}"
            >
            <div class="control__icon" (click)="passwordViewToggle()">
              <app-icon icon="eye"></app-icon>
            </div>
          </div>
        </div>
      </div>

      <div class="popup__footer">

        <re-captcha formControlName="recaptchaToken" [siteKey]="captchaKey" *ngIf="!isAdmin"></re-captcha>

        <div class="popup__error" *ngIf="!!error">
          Error: <span>{{ error }}</span>
        </div>

        <div class="popup__buttons">
          <button type="submit"
                  class="popup__submit"
                  [disabled]="!form.valid"
          >
            {{ isAdmin ? 'Register' : 'Sign up' }}
          </button>
        </div>

        <p class="popup__footer-text" *ngIf="!isAdmin">
          By clicking sign up button you are agreeing with the <a (click)="navigate('/terms')">Terms</a>
        </p>
      </div>
    </form>

    <form novalidate
          (ngSubmit)="onSubmitBt(formBt)"
          [formGroup]="formBt"
          *ngIf="currentSource === SOURCES.bt"
    >
      <div class="popup__body">
        <div class="popup__field">
          <div class="control">
            <label for="login" class="control__label">
              Username
            </label>
            <input id="login" class="control__input" type="text" formControlName="login"
                   [ngClass]="{'control__input--error': !formBt.pristine && !formBt.controls.login.valid}"
            >
          </div>
        </div>

        <div class="popup__field">
          <div class="control">
            <label for="email-bt" class="control__label">
              Email
            </label>
            <input id="email-bt" class="control__input" type="email" formControlName="email"
                   [ngClass]="{'control__input--error': !formBt.pristine && !formBt.controls.email.valid}"
            >
          </div>
        </div>

        <div class="popup__field">
          <div class="control">
            <label for="password" class="control__label">
              Password
            </label>
            <input id="password" class="control__input" [type]="isPassword ? 'password' : 'text'"
                   formControlName="password"
                   [ngClass]="{'control__input--error': !formBt.pristine && !formBt.controls.password.valid}"
            >
            <div class="control__icon" (click)="passwordViewToggle()">
              <app-icon icon="eye"></app-icon>
            </div>
          </div>
        </div>
      </div>

      <div class="popup__body">
        <div class="popup__field">
          <div class="control">
            <label for="name" class="control__label">
              Name (optional)
            </label>
            <input id="name" class="control__input" type="text" formControlName="name">
          </div>
        </div>

        <div class="popup__field popup__field--half">
          <div class="control">
            <label for="skype" class="control__label">
              Skype (optional)
            </label>
            <input id="skype" class="control__input" type="text" formControlName="skype">
          </div>
        </div>

        <div class="popup__field popup__field--half">
          <div class="control">
            <label for="telegram" class="control__label">
              Telegram (optional)
            </label>
            <input id="telegram" class="control__input" type="text" formControlName="telegram">
          </div>
        </div>

        <div class="popup__field popup__field--half">
          <div class="control">
            <label class="control__label">
              Payment method
            </label>
            <app-select
              placeholder="Select method"
              [itemsList]="PAYMENT_METHODS"
              formControlName="paymentType"
            ></app-select>
          </div>
        </div>

        <div class="popup__field popup__field--half">
          <div class="control">
            <label class="control__label">
              Min payout
            </label>
            <app-select
              placeholder="Min payout"
              [itemsList]="MIN_PAYOUT"
              formControlName="minPayment"
            ></app-select>
          </div>
        </div>

        <div class="popup__field" *ngIf="formBt.controls.paymentType.value === 'paxum'">
          <div class="control">
            <label for="paxumEmail" class="control__label">
              Paxum email
            </label>
            <input id="paxumEmail" class="control__input" type="email" formControlName="paxumEmail"
                   [ngClass]="{'control__input--error': !formBt.pristine && !formBt.controls.paxumEmail.valid}">
          </div>
        </div>
      </div>

      <div class="popup__footer">

        <re-captcha formControlName="recaptchaToken" [siteKey]="captchaKeyBt" *ngIf="!isAdmin"></re-captcha>

        <div class="popup__error" *ngIf="!!error">
          Error: <span>{{ error }}</span>
        </div>

        <div class="popup__buttons">
          <button type="submit"
                  class="popup__submit"
                  [disabled]="!formBt.valid"
          >
            {{ isAdmin ? 'Register' : 'Sign up' }}
          </button>
        </div>

        <p class="popup__footer-text" *ngIf="!isAdmin">
          By clicking sign up button you are agreeing with the <a (click)="navigate('/terms')">Terms</a>
        </p>
      </div>
    </form>
  </app-loading-indicator>
</div>
