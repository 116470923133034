<header class="header">
  <div class="header__container">
    <a class="header__logo"
       routerLink="/"
    >
      <img src="assets/img/logo.svg" alt="BrokerTraffic.com" width="293">
    </a>

    <button class="header__menu-button"
            [ngClass]="{'header__menu-button--open': isMobileMenuOpen}"
            *ngIf="isMobile"
            (click)="toggleMobileMenu()"
    ><span></span></button>

    <div class="header__content" *ngIf="!isMobile || isMobileMenuOpen">
      <nav class="header__navigation">
<!--        TODO: вернуть условия авторизации-->
<!--        <ul class="header__navigation-list" *ngIf="isAuth; else notAuth">-->
        <ul class="header__navigation-list" *ngIf="isAuth">
          <li>
            <a class="header__navigation-link"
               routerLink="/main"
               routerLinkActive="header__navigation-link--active"
            >
              Main page
            </a>
          </li>
          <li>
            <a class="header__navigation-link"
               routerLink="/channels"
               routerLinkActive="header__navigation-link--active"
            >
              Networks / Channels
            </a>
          </li>

          <li>
            <a class="header__navigation-link"
               routerLink="/videos"
               routerLinkActive="header__navigation-link--active"
            >
              Videos
            </a>
          </li>

          <li>
            <a class="header__navigation-link"
               routerLink="/feeds"
               routerLinkActive="header__navigation-link--active"
            >
              Feeds
            </a>
          </li>

          <!--          <li *ngIf="!isAdmin">-->
          <!--            <a class="header__navigation-link"-->
          <!--               routerLink="/sites"-->
          <!--               routerLinkActive="header__navigation-link&#45;&#45;active"-->
          <!--            >-->
          <!--              Sites-->
          <!--            </a>-->
          <!--          </li>-->

          <!--          <li *ngIf="isAdmin">-->
          <!--            <a class="header__navigation-link"-->
          <!--               routerLink="/admin-sites"-->
          <!--               routerLinkActive="header__navigation-link&#45;&#45;active"-->
          <!--            >-->
          <!--              Sites-->
          <!--            </a>-->
          <!--          </li>-->

          <li *ngIf="isAdmin">
            <a class="header__navigation-link"
               routerLink="/admin-webmasters/users"
               routerLinkActive="header__navigation-link--active"
            >
              Webmasters
            </a>
          </li>


        </ul>

        <ng-template #notAuth>
          <ul class="header__navigation-list">
            <li>
              <a class="header__navigation-link"
              >
                Not auth
              </a>
            </li>
            <!--            <li>-->
            <!--              <a class="header__navigation-link"-->
            <!--                 (click)="scrollToElement('content')"-->
            <!--                 routerLink="/promo"-->
            <!--              >-->
            <!--                Content program-->
            <!--              </a>-->
            <!--            </li>-->
            <!--            <li>-->
            <!--              <a class="header__navigation-link"-->
            <!--                 routerLink="/sites"-->
            <!--                 routerLinkActive="header__navigation-link&#45;&#45;active"-->
            <!--              >-->
            <!--                Sites-->
            <!--              </a>-->
            <!--            </li>-->
          </ul>
        </ng-template>
      </nav>

      <div class="header__user-panel">
        <span *ngIf="isAuth && userName"
              class="header__user-name"
              (click)="editUser()"
        >
          <span class="header__user-avatar"
                *ngIf="userAvatar; else noAvatar">
            <img [src]="userAvatar">
          </span>
          <ng-template #noAvatar>
            <app-icon icon="user"></app-icon>
          </ng-template>
          Hi,&nbsp;<span>{{ userName ? userName : 'No name' }}</span>
        </span>

        <a *ngIf="!isAuth"
           class="header__button"
           (click)="userLogin()"
        >
          SIGN IN
        </a>

        <a *ngIf="!isAuth"
           class="header__button-fill"
           (click)="userRegister()"
        >
          SIGN UP
        </a>

        <a *ngIf="isAuth"
           class="header__button"
           (click)="userLogout()"
        >
          LOG OUT
        </a>
      </div>
    </div>
  </div>
</header>
