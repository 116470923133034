<div class="popup">

  <div class="popup__close"
       (click)="closeModal()"
  >
    <app-icon icon="close"></app-icon>
  </div>

  <div class="popup__footer">
    <div class="popup__buttons">
      <button type="submit"
              class="popup__submit"
              (click)="confirmModal()"
      >
        Pay
      </button>

      <button type="submit"
              class="popup__alt"
              (click)="closeModal()"
      >
        Decline
      </button>
    </div>
  </div>
</div>
