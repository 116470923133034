import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

// RxJs
import { finalize } from 'rxjs/operators';

// Interfaces
import { SignInForm } from '@app/modules/shared/interfaces/sign-in-form';

// Services
import { AuthService } from '@app/modules/core/services/auth.service';
import { LoginService } from '@app/modules/core/services/login.service';
import { ConfigStorageService } from '@app/modules/core/services/config-storage.service';

// Components
import { RestorePasswordComponent } from '@app/modules/shared/components/modals/restore-password/restore-password.component';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss']
})

export class LoginComponent implements OnInit {
  private redirectUrl: string;

  public form: FormGroup;
  public error: string;

  public isPassword = true;
  public isLoading = false;

  public SOURCES = {
    cp: 'cp',
    bt: 'bt'
  };

  public currentSource = 'cp';

  constructor(
    private readonly fb: FormBuilder,
    private readonly activeModal: NgbActiveModal,
    private readonly loginService: LoginService,
    private readonly authService: AuthService,
    private readonly modalService: NgbModal,
    private readonly configStorageService: ConfigStorageService,
  ) {
    this.redirectUrl = this.configStorageService.get('redirectUrl');
  }

  private initForm(): void {
    this.form = this.fb.group({
      username: new FormControl('', Validators.required),
      password_hash: new FormControl('', Validators.required)
    });

    this.form.valueChanges.subscribe(() => {
      this.resetError();
    })
  }

  public setCurrentSource(source: string) {
    this.form.reset();
    this.currentSource = source;
  }

  resetError(): void {
    if (!!this.error) {
      this.error = '';
    }
  }

  passwordViewToggle(): void {
    this.isPassword = !this.isPassword;
  }

  onSubmit({ value, valid }: { value: SignInForm, valid: boolean }): void {
    if (valid) {
      this.isLoading = true;

      if (this.currentSource === this.SOURCES.cp) {
        const request = this.loginService.login(this.form.getRawValue());

        request
          .pipe(
            finalize(() => {this.isLoading = false})
          )
          .subscribe({
            next: (res) => {
              this.authService.auth(res.data);
              this.activeModal.close();
            },
            error: (err) => {
              this.error = err ? err : 'Something went wrong';
            }
          });
      } else {
        const request = this.loginService.loginBT(this.form.getRawValue());

        request
          .pipe(
            finalize(() => {this.isLoading = false})
          )
          .subscribe({
            next: (res) => {
              const url = `${this.redirectUrl}?token=${res.data.token}`;

              window.open(url, '_top');
            },
            error: (err) => {
              // this.error = err.error;
              this.error = err ? err : 'Something went wrong';
            }
          });
      }
    }
  }

  restorePassword(): void {
    this.closeModal();

    this.modalService.open(RestorePasswordComponent, {
      keyboard: true,
      size: '420',
      backdrop: true,
      centered: true
    })
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }

  ngOnInit(): void {
    this.initForm();
  }
}
