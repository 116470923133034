import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'app-upload-image',
  templateUrl: 'upload-image.component.html',
  styleUrls: ['upload-image.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadImageComponent),
      multi: true
    }
  ]
})

export class UploadImageComponent {
  @Input() width: number;
  @Input() height: number;
  @Input() text: string;

  imageWidth: number;
  imageHeight: number;

  public imagePath;
  public imgURL: any;
  public md5: string;
  public path: string;
  public message: string;

  constructor() {
  }

  // Установить выбранное значение
  private setSelectedValue(v: any): void {
    if (typeof v === 'string') {
      if (v.indexOf('http') !== -1) {
        this.path = v;
      } else {
        this.md5 = v;
      }
    }
    this.propagateChange(v);
  }

  public remove(): void {
    this.imgURL = null;
    this.writeValue(null);
    this.selectValue(null);
  }

  public preview(files) {
    this.imageWidth = null;
    this.imageHeight = null;
    if (files.length === 0) {
      return;
    }

    const mimeType = files[ 0 ].type;
    if (mimeType.match(/image\/*/) == null) {
      console.warn("Only images are supported.");
      return;
    }

    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[ 0 ]);
    reader.onload = (_event) => {
      const img = new Image();
      if (typeof reader.result === "string") {
        img.src = reader.result;
        img.onload = () => {
          this.imageWidth = img.width;
          this.imageHeight = img.height;
        }
      }
      this.imgURL = reader.result;
    }

    this.setSelectedValue(files);
  }


  /** Связываем через ngModel */
  public writeValue(value: any): void {
    this.imageWidth = this.width;
    this.imageHeight = this.height;
    if (!value) {
      this.path = '';
      this.md5 = '';
      return;
    }

    this.setSelectedValue(value);
  }

  /** Выбор значения селекта */
  public selectValue(value: any): void {
    this.setSelectedValue(value);
  }

  // Метод интерфейса ControlValueAccessor
  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  // Метод интерфейса ControlValueAccessor
  // tslint:disable-next-line:no-empty
  public registerOnTouched(fn: any): void {
  }

  // Колбэк на value change
  // tslint:disable-next-line:no-empty
  public propagateChange = (fn: any): void => {
  };
}
