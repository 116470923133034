<div class="popup">
  <div class="popup__header">
    Restore password

    <div class="popup__close"
         (click)="closeModal()"
    >
      <app-icon icon="close"></app-icon>
    </div>
  </div>


  <form novalidate
        (ngSubmit)="onSubmit(form)"
        [formGroup]="form"
  >
    <div class="popup__body">
      <div class="popup__field">
        <div class="control">
          <label for="email" class="control__label">
            Your email
          </label>
          <input id="email" class="control__input" type="text" formControlName="email"
                 [ngClass]="{'control__input--error': !form.pristine && !form.controls.email.valid}"
          >
        </div>
      </div>
    </div>

    <div class="popup__footer">

      <re-captcha formControlName="recaptchaToken" [siteKey]="captchaKey"></re-captcha>

      <div class="popup__error" *ngIf="!!error">
        Error: <span>{{ error }}</span>
      </div>

      <div class="popup__buttons">
        <button type="submit"
                class="popup__submit"
                [disabled]="!form.valid"
        >
          Continue
        </button>
      </div>

    </div>
  </form>

</div>
