import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

// RxJs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
// import { LoaderService } from '@app/modules/shared/services';


@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(
    // private readonly loaderService: LoaderService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // this.loaderService.isLoading.next(true);
    // return next.handle(request).pipe(
    //   map(
    //     () => this.loaderService.isLoading.next(false)
    //   )
    // )

    return  next.handle(request);
  }
}
