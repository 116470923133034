import {Injectable} from '@angular/core';
import {plainToClass} from 'class-transformer';

// RxJs
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

// Services
import {HttpService} from '@app/modules/core';

// Models
import {ThumbSizeModel} from '@app/modules/shared/models/thumb-size.model';

@Injectable()
export class ThumbsService {

  constructor(
    private readonly httpService: HttpService
  ) {
  }

  // getThumbsSizes(): Observable<any> {
  //   return this.httpService.send(
  //     {
  //       url: 'thumbs/sizes',
  //       method: 'GET'
  //     })
  //     .pipe(
  //       map((res) => plainToClass(ThumbSizeModel, res.data))
  //     )
  // }

  /**
   * Delete specified thumb
   * @param {string} id
   */
  deleteThumb(id: string): Observable<any> {
    return this.httpService.send(
      {
        url: `thumbs/${id}`,
        method: 'DELETE'
      });
  }

  /**
   * Upload thumb image related to video
   * @param {string} id
   */
  updateRelatedThumb(id: string): Observable<any> {
    return this.httpService.send(
      {
        url: `thumbs/${id}/upload`,
        method: 'PUT'
      });
  }
}
