import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { plainToClass } from 'class-transformer';

// RxJS
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { HttpService } from '@app/modules/core';

// Models
import { VideoModel } from '@app/modules/shared/models/video.model';
import { VideoThumbModel } from '@app/modules/shared/models/video-thumb.model';

@Injectable()
export class VideosService {
  private readonly videoListUpdateSubject = new BehaviorSubject<any>(null);
  private readonly videoListUpdateSubjectObservable = this.videoListUpdateSubject.asObservable();

  constructor(
    private readonly httpService: HttpService
  ) {
  }

  update(options: boolean): void {
    this.videoListUpdateSubject.next(options);
  }

  onUpdate(then: (options: boolean) => void): Subscription {
    return this.videoListUpdateSubjectObservable.subscribe(then);
  }

  /**
   * Crate new video
   * @param {VideoModel} formData
   */
  createVideo(formData: any): Observable<any> {
    return this.httpService.multi(
      {
        url: 'videos',
        method: 'POST',
        body: formData,
        // reportProgress: true,
        // observe: 'events'
      })
      .pipe(
        map((res) => res.data[0].guid)
      )
  }

  /**
   * Upload video file
   * @param {string} id
   * @param formData
   */
  uploadVideo(id: string, formData: any): Observable<any> {
    return this.httpService.multi(
      {
        url: `videos/${id}/upload`,
        method: 'PUT',
        body: formData,
        reportProgress: true,
        observe: 'events'
      });
  }

  /**
   * Get video
   * @param {string} id
   */
  getVideo(id: string): Observable<any> {
    return this.httpService.send(
      {
        url: `videos/${id}`,
        method: 'GET',
      })
      .pipe(
        map((res) => plainToClass(VideoModel, res.data))
      );
  }

  /**
   * Update video
   * @param {string} id
   * @param {VideoModel} formData
   */
  updateVideo(id: string, formData: FormData): Observable<any> {
    return this.httpService.multi(
      {
        url: `videos/${id}`,
        method: 'PUT',
        body: formData
      });
  }

  /**
   * Delete video
   * @param {string} id
   */
  deleteVideo(id: string): Observable<any> {
    return this.httpService.send(
      {
        url: `videos/${id}`,
        method: 'DELETE',
      });
  }

  /**
   * Set video thumb active status
   * @param {string} id
   */
  setVideoThumbActive(id: string): Observable<any> {
    return this.httpService.send(
      {
        url: `thumbs/${id}/active`,
        method: 'PUT',
      })
      .pipe(
        map((res) => plainToClass(VideoThumbModel, res.data))
      );
  }

  /**
   * Get videos from channel
   * @param {string} id
   * @param {string} status
   * @param {string} page
   * @param {string} limit
   */
  getChannelVideos(id: string, status: string, page: string = '1', limit: string = '10'): Observable<any> {
    const queryParams = new HttpParams()
      .append('status', status)
      .append('page', page)
      .append('limit', limit)

    return this.httpService.send(
      {
        url: `channels/${id}/videos`,
        method: 'GET',
        queryParams
      })
      // .pipe(
      //   map((res) => plainToClass(VideoModel, res.data))
      // )
  }

  /**
   * Get channel videos count
   * @param {string} id
   */
  getChannelVideosCount(id: string): Observable<any> {
    return this.httpService.send(
      {
        url: `channels/${id}/videos/count`,
        method: 'GET',
      })
      .pipe(
        map((res) => res.data)
      )
  }

  /**
   * Get all user videos
   * @param {string} status
   * @param {string} page
   * @param {string} limit
   */
  getAllUserVideos(status: string, page: string = '1', limit: string = '10'): Observable<any> {
    const queryParams = new HttpParams()
      .append('status', status)
      .append('page', page)
      .append('limit', limit)

    return this.httpService.send(
      {
        url: `videos`,
        method: 'GET',
        queryParams
      });
      // .pipe(
      //   map((res) => plainToClass(VideoModel, res.data))
      // )
  }

  /**
   * Get all videos count
   */
  getAllVideosCount(): Observable<any> {
    return this.httpService.send(
      {
        url: `videos/count`,
        method: 'GET',
      })
      .pipe(
        map((res) => res.data)
      )
  }
}
