import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Route,
  Router,
  RouterStateSnapshot
} from '@angular/router';

// Services
import { UserService } from '@app/modules/core';

@Injectable()
export class IsAuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private readonly userService: UserService,
    private readonly router: Router
  ) {
  }

  private isAuth(): boolean {
    if (this.userService.isLogged()) {
      return true;
    }

    this.router.navigate(['/promo']);

    return false;
  }

  canLoad(route: Route): boolean {
    return this.isAuth();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.isAuth();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.isAuth();
  }
}
