import { Injectable } from '@angular/core';

// RxJs
import { Observable } from 'rxjs';

// Services
import { HttpService } from '@app/modules/core';

@Injectable()
export class ImagesService {
  constructor(
    private readonly httpService: HttpService
  ) {
  }

  getImage(id: string): Observable<any> {
    return this.httpService.send(
      {
        url: `images/${id}`,
        method: 'GET'
      }
    )
  }

  deleteImage(id: string): Observable<any> {
    return this.httpService.send(
      {
        url: `images/${id}`,
        method: 'DELETE'
      }
    )
  }

  uploadImage(id: string, file: any): Observable<any> {

    return this.httpService.multi(
      {
        url: `images/${id}/upload`,
        method: 'PUT',
        body: file,
      }
    )
  }
}
