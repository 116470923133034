import { ModelModel } from '@app/modules/shared/models/model.model';
import { VideoThumbModel } from '@app/modules/shared/models/video-thumb.model';
import { ErrorVideoModel } from '@app/modules/shared/models/error-video.model';
import { VideoStatModel } from '@app/modules/shared/models/video-stat-model.model';

export class VideoModel {
  categories: string[];
  channelGUID: string;
  customThumb: VideoThumbModel;
  description: string;
  duration: number;
  errors: ErrorVideoModel[];
  format: string;
  guid: string;
  md5: string;
  models: ModelModel[];
  thumbs: VideoThumbModel[];
  stat: VideoStatModel;
  name: string;
  quality: string;
  uploadStatus: string;
  url: string;
  created: number;
  views: number;
  messages: number;
  likes: number;
  dislikes: number;
  tags: string[];
}
