import { Injectable } from '@angular/core';

// RxJs
import { Observable } from 'rxjs';

// Service
import { HttpService } from '@app/modules/core/services/http.service';
import { ConfigStorageService } from '@app/modules/core/services/config-storage.service';

// Interfaces
import { PasswordChangeForm } from '@app/modules/change-password/interfaces/password-change-form';
import {map} from "rxjs/operators";
import {plainToClass} from "class-transformer";
import {WebmasterModel} from "@app/modules/admin-webmasters/models/webmaster-model";

@Injectable()
export class LoginService {
  private readonly httpUrlBt: string;

  constructor(
    private readonly httpService: HttpService,
    private readonly configStorageService: ConfigStorageService,
  ) {
    this.httpUrlBt = this.configStorageService.get('httpURLBT');
  }

  loginBT({ username, password_hash }: { username: string; password_hash: string }): Observable<any> {
    const body = {
      login: username,
      password: password_hash
    };

    return this.httpService.send(
      {
        url: 'auth/login',
        method: 'POST',
        host: this.httpUrlBt,
        body
      });
  }

  login({ username, password_hash }: { username: string; password_hash: string }): Observable<any> {
    const body = {
      username,
      password_hash
    };

    return this.httpService.send(
      {
        url: 'login',
        method: 'POST',
        body
      });
  }

  restorePassword({email, recaptchaToken}: {email: string; recaptchaToken: string}): Observable<any> {
    const body = {
      email,
      recaptchaToken
    };

    return this.httpService.send(
      {
        url: 'auth/recovery-password',
        method: 'POST',
        body
      });
  }

  changePassword(formData: PasswordChangeForm): Observable<any> {

    return this.httpService.send(
      {
        url: 'auth/change-password',
        method: 'POST',
        body: formData
      });
  }

  loginByGuid(guid: string): Observable<any> {
    return this.httpService.send(
      {
        url: `load-by-guid/${guid}`,
        method: 'POST'
      })
      .pipe(
        map((res) => res.data)
      );
  }
}
