import { Component, Input } from '@angular/core';

// Services
import { ConfigStorageService } from '@app/modules/core/services/config-storage.service';

@Component({
  selector: 'app-pic',
  templateUrl: 'pic.component.html',
  styleUrls: ['pic.component.scss']
})

export class PicComponent {
  @Input() md5: string;
  @Input() path: string;
  @Input() width: number;
  @Input() height: number;

  http: string;

  constructor(
    private readonly configStorageService: ConfigStorageService
  ) {
    this.http = this.configStorageService.get('picURL');
  }

  getSymbol(pos: number): string {
    return this.md5 ? this.md5.charAt(pos) : '';
  }

  getSrc(): string {
    // TODO: тут вероятней всего нужно будет исправлять при изменении апи со стороны бекенда
    if (this.path) {
      return this.path;
    } else {
      return `${this.http}/${this.getSymbol(0)}/${this.getSymbol(1)}/${this.getSymbol(2)}/${this.md5}`
    }
  }
}
