<div class="select-field">

  <div class="select-field__select"
       [ngClass]="{
			'select-field__select--dropdown-active' : isDropDownListShow
		}">

		<span *ngIf="selectedTitle.length > 0; else placeholderText;" class="select-field__select--selected">
			{{ selectedTitle }}
		</span>

    <ng-template #placeholderText>
			<span class="select-field__placeholder">
				{{ placeholder }}
			</span>
    </ng-template>

    <app-icon icon="dropdown"></app-icon>
  </div>

  <div *ngIf="isDropDownListShow"
       class="select-field__dropdown">

    <ul *ngIf="itemsList.length > 0; else emptyItems;" class="select-field__dropdown-list">

      <li class="select-field__dropdown-item"
          *ngIf="isAllAvailable"
          [ngClass]="{ 'select-field__dropdown-item--active': selectedTitle.length === 0 }"
          (click)="clearSelected()"
      >
        All
      </li>

      <li *ngFor="let item of itemsList; trackBy: itemsListTrackBy"
          (click)="selectValue(item.guid)"
          class="select-field__dropdown-item"
          [ngClass]="{ 'select-field__dropdown-item--active': isItemActive(item.guid) }">
        {{ item.domain }}
      </li>

    </ul>

    <ng-template #emptyItems>
      <ul class="select-field__dropdown-list">

        <li class="select-field__dropdown-item select-field__dropdown-item--empty">
          Ничего не найдено
        </li>

      </ul>
    </ng-template>

  </div>
</div>
