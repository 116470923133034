import { Injectable } from '@angular/core';

// RxJs
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class UserService {
  private token: string = null;
  private refreshToken: string = null;
  private isUserAdmin: boolean = null;
  private countMethod: string = null;
  private userName: string = null;
  private userGuid: string = null;
  private userAvatar: string = null;

  authenticated = new BehaviorSubject(false);
  isAdminSubject = new BehaviorSubject(false);
  userCountMethod = new BehaviorSubject('');
  userNameSubject = new BehaviorSubject('');
  userAvatarSubject = new BehaviorSubject('');
  userGuidSubject = new BehaviorSubject('');

  constructor() {
    const parameters = new URLSearchParams(window.location.search);
    this.token = localStorage.getItem('token');
    if (!this.token && parameters.get('token')) {
      localStorage.clear();
      this.setToken(parameters.get('token'));
    }
    this.refreshToken = localStorage.getItem('refresh_token');
    this.isUserAdmin = JSON.parse(localStorage.getItem('isAdmin'));
    this.countMethod = localStorage.getItem('countMethod');
    this.userName = localStorage.getItem('name');
    this.userAvatar = localStorage.getItem('avatar');
    this.userGuid = localStorage.getItem('guid');

    this.authenticated.next(!!this.token);
    this.isAdminSubject.next(!!this.isUserAdmin);
    this.userCountMethod.next(this.countMethod);
    this.userNameSubject.next(this.userName);
    this.userAvatarSubject.next(this.userAvatar);
    this.userGuidSubject.next(this.userGuid);
  }

  setToken(token): void {
    localStorage.setItem('token', `${token}`);

    this.token = `Bearer ${token}`;

    this.login();
  }

  setRefreshToken(refresh_token: string) {
    localStorage.setItem('refresh_token', `${refresh_token}`);

    this.refreshToken = `${refresh_token}`;
  }

  setUserAdmin(roles) {
    const searchRole = 'admin';
    const isAdmin = !!(roles && roles.find((el) => el === searchRole));

    this.isUserAdmin = isAdmin;

    localStorage.setItem('isAdmin', `${isAdmin}`);

    this.checkAdmin();
  }

  // setCountMethod(countMethod) {
  //   localStorage.setItem('countMethod', `${countMethod}`);
  //   this.countMethod = countMethod;
  //   this.userCountMethod.next(this.countMethod);
  // }

  setUserGuid(guid) {
    localStorage.setItem('guid', guid);
    this.userGuid = guid;
    this.userGuidSubject.next(this.userGuid);
  }

  setUserName(name) {
    localStorage.setItem('name', name);
    this.userName = name;
    this.userNameSubject.next(this.userName);
  }

  setUserAvatar(avatar) {
    localStorage.setItem('avatar', avatar);
    this.userAvatar = avatar;
    this.userAvatarSubject.next(this.userAvatar);
  }

  checkAdmin() {
    this.isAdminSubject.next(!!this.isUserAdmin);
  }

  getToken(): string {
    return this.token;
  }

  getUserGuid(): string {
    return this.userGuid;
  }

  getRefreshToken(): string {
    return this.refreshToken;
  }

  logout() {
    this.authenticated.next(false);
  }

  login() {
    this.authenticated.next(true);
  }

  removeTokens(): void {
    this.token = null;
    this.isUserAdmin = null;
    this.countMethod = null;
    this.userName = null;
    this.userAvatar = null;


    localStorage.clear();

    this.logout();
  }

  isLogged(): boolean {
    return !!this.token;
  }

  isAdmin(): boolean {
    return this.isUserAdmin;
  }
}
