import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})

export class DurationPipe implements PipeTransform {
  transform(value:  number): string {
    let result;

    let s = Math.floor(value);
    const h = Math.floor(s / 3600);
    s -= h * 3600;
    const m = Math.floor(s / 60);
    s -= m * 60;

    let hours = h > 0 && h < 10 ? `0${h}` : `${h}`;
    let minutes = m > 0 && m < 10 ? `0${m}` : `${m}`;
    let seconds = s === 0 ? '00' : s > 0 && s < 10 ? `0${s}` : `${s}`;

    if (h > 0) {
      result = `${hours}:${minutes}:${seconds}`;
    } else {
      result = `${minutes}:${seconds}`;
    }
    return result;
  }
}
