<div class="notification"
     [ngClass]="{
				'notification--visible': isShow,
				'notification--error': options?.status === statuses.Error
			}">
  <ng-container *ngIf="options">

    <div class="notification__content">

      <div class="notification__message">
        <div class="notification__text">
          {{ options.text }}
        </div>
      </div>
    </div>

    <div (click)="close()" class="notification__close-button">
      X
    </div>

  </ng-container>
</div>
