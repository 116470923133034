<div class="popup">
  <div class="popup__header">
    Content Program will be available soon. Check our updates pls

    <div class="popup__close"
         (click)="closeModal()"
    >
      <app-icon icon="close"></app-icon>
    </div>
  </div>
</div>
