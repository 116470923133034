import { Injectable } from '@angular/core';
import { plainToClass } from "class-transformer";

// Services
import { HttpService } from "@app/modules/core";

// RxJs
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

// Models
import { NetworkModel } from '@app/modules/shared/models/network.model';

@Injectable()
export class NetworksService {
  constructor(
    private readonly httpService: HttpService
  ) {
  }

  /**
   * Get all networks list
   */
  getUserNetworksList(): Observable<any> {
    return this.httpService.send(
      {
        url: `networks`,
        method: 'GET'
      })
      .pipe(
        map(res => plainToClass(NetworkModel, res.data))
      );
  }

  /**
   * Create network
   * @param {ChannelModel} data
   */
  createNetwork(data: FormData): Observable<any> {
    return this.httpService.multi(
      {
        url: 'networks',
        method: 'POST',
        body: data
      });
  }

  /**
   * Delete network
   * @param {string} id
   */
  deleteNetwork(id: string): Observable<any> {
    return this.httpService.send(
      {
        url: `networks/${id}`,
        method: 'DELETE'
      });
  }

  /**
   * Get network info
   * @param {string} id
   */
  getNetwork(id: string): Observable<any> {
    return this.httpService.send(
      {
        url: `networks/${id}`,
        method: 'GET'
      })
      .pipe(
        map(res => plainToClass(NetworkModel, res.data[ 0 ]))
      );
  }

  /**
   * Update network data
   * @param {string} id
   * @param {FormData} formData
   */
  updateNetwork(id: string, formData: FormData): Observable<any> {
    return this.httpService.multi(
      {
        url: `networks/${id}`,
        method: 'PUT',
        body: formData
      });
  }

  /**
   * Delete all network images if any exist
   * @param {string} id
   */
  deleteNetworkImages(id: string): Observable<any> {
    return this.httpService.send(
      {
        url: `networks/${id}/images`,
        method: 'DELETE'
      });
  }

  /**
   * Set channel new owner
   * @param {string} id
   * @param {Object} data
   */
  setNetworkOwner(id: string, data: Object): Observable<any> {
    return this.httpService.send(
      {
        url: `networks/${id}/assign`,
        method: 'PUT',
        body: data
      });
  }
}
