import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Router
import { Router } from '@angular/router';

// RxJs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { UserService } from '@app/modules/core/services/user.service';
import { ConfigStorageService } from '@app/modules/core/services/config-storage.service';

const AUTH_URL = '/';

@Injectable()
export class AuthService {
  private httpUrl: string;

  constructor(
    private readonly userService: UserService,
    private readonly route: Router,
    private readonly http: HttpClient,
    private readonly configStorageService: ConfigStorageService
  ) {
  }

  getToken(): string {
    return localStorage.getItem('token');
  }

  isAuthenticated(): boolean {
    const token = this.getToken();

    return !!token;
  }

  auth({ token, refresh_token, roles, countMethod, utmLabel, ...data }) {
    this.userService.setToken(token);
    this.userService.setRefreshToken(refresh_token);

    this.route.navigate([AUTH_URL]);
  }


  refreshToken(): Observable<any> {
    this.httpUrl = this.configStorageService.get('httpURL');

    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded'
    });

    const refreshToken = this.userService.getRefreshToken();

    const body = `grant_type=refresh_token&refresh_token=${refreshToken}`;

    return this.http.post<any>(
      `${this.httpUrl}/token`,
      body,
      { headers: headers })
      .pipe(
        map((res) => {
          this.userService.setToken(res.access_token);

          return res;
        })
      )
  }
}
