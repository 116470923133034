<div class="custom-cover">
  <div class="custom-cover__preview" *ngIf="imgURL">
    <img [src]="imgURL">
  </div>

  <div class="custom-cover__control">
    <label class="custom-cover__button">
      <input type="file"
             #file
             (change)="preview(file.files)"
             accept='image/*'
      />
      Reload banner
    </label>


    <div class="custom-cover__desc">
      <h2>480x270 px</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    </div>
  </div>
</div>
