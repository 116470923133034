export class WebmasterModel {
  avatar: string;
  blocked: boolean;
  blockingReason: string;
  channels: number;
  created: number;
  email: string;
  identityId: string;
  mainSite: string;
  passwordHash: string;
  roles: [];
  skype: string;
  telegram: string;
  username: string;
  verifiedEmail: boolean;
}
