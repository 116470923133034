<div class="upload-image">
  <div class="upload-image__preview"
       *ngIf="imgURL"
       [ngStyle]="{'width': width + 'px', 'height': height + 'px'}"
  >
    <img [src]="imgURL">
  </div>

  <div class="upload-image__preview"
       [ngStyle]="{'width': width + 'px', 'height': height + 'px'}"
       *ngIf="(md5 || path) && !imgURL"
  >
    <app-pic [md5]="md5"
             [path]="path"
    ></app-pic>
  </div>

  <div class="upload-image__control">
    <label class="upload-image__button">
      <input type="file"
             #file
             (change)="preview(file.files)"
             accept='image/jpeg'
      />
      {{ (md5 || path) && !imgURL ? 'Reload image' : 'Load image' }}
    </label>

    <label class="upload-image__button upload-image__button--remove"
         *ngIf="(md5 || path || imgURL)"
    >
      <input type="button"
             (click)="remove()"
      />
      Remove file
    </label>

    <div class="upload-image__desc">
      <h2 *ngIf="width !== imageWidth || height !== imageHeight; else correct">
        Your image {{imageWidth}}x{{imageHeight}} px <span>(Recommended {{ width }}x{{ height }} px)</span>
      </h2>
      <ng-template #correct>
        <h2 *ngIf="imgURL">{{width}}x{{height}} px</h2>
      </ng-template>
      <p>{{ text }}</p>
    </div>
  </div>
</div>
