import { Component, ElementRef, forwardRef, HostListener, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

// Models
import { SelectComponentItemModel } from '@app/modules/shared/models/select-component-item.model';

@Component({
  selector: 'app-select',
  templateUrl: 'select.component.html',
  styleUrls: ['select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})

export class SelectComponent implements OnInit {
  @Input() public itemsList: any = [];
  @Input() public placeholder = '';
  @Input() public isAllAvailable = false;
  @Input() public isClearAvailable = false;

  private selectedValue: any = '';

  public isDropDownListShow = false;
  public selectedTitle = '';

  isDisabled;
  loading = false;

  /** При клике по селекту */
  @HostListener('click')
  public clickInside(): void {
    this.toggleDropDownList();
  }

  /** Закрытие выпадающего окна при клике снаружи */
  @HostListener('document:click', ['$event'])
  public clickedOutside(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isDropDownListShow = false;
    }
  }

  constructor(
    private readonly elementRef: ElementRef
  ) {
  }

  // Удаляет все выбранное
  public removeSelected(): void {
    this.selectedTitle = '';
    this.selectedValue = '';

    this.propagateChange('');
  }

  // Очистить выбранное значение
  public clearSelected(): void {
    this.selectedTitle = '';
    this.selectedValue = '';

    this.propagateChange('default');
  }

  // Установить выбранное значение
  private setSelectedValue(v: any): void {
    if (this.itemsList) {
      let value: SelectComponentItemModel;
      try {
        value = this.itemsList
          .find((item: SelectComponentItemModel) => item.value === v);
      } catch (err) {
        value = new SelectComponentItemModel();
        value.value = '';
        value.title = 'ошибка';
        this.isDisabled = true;
      }

      this.selectedTitle = value ? value.title : '';
      this.selectedValue = value;

      if (value) {
        this.propagateChange(value.value);
      }
    }
  }

  /** Выбор значения селекта */
  public selectValue(value: any): void {
    this.setSelectedValue(value);
  }

  /** Связываем через ngModel */
  public writeValue(value: any): void {
    if (!value) {
      this.clearSelected();

      return;
    }

    this.selectedValue = value;

    if (!this.loading) {
      this.setSelectedValue(value);
    }
  }

  // Метод интерфейса ControlValueAccessor
  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  // Открыть/Закрыть список элементов
  public toggleDropDownList(): void {
    this.isDropDownListShow = !this.isDropDownListShow && !this.isDisabled;
  }

  // TrackBy для списка элементов
  public itemsListTrackBy(index: number, item: any): string {
    return item.value;
  }

  // Элемент выбран
  public isItemActive(value: any): boolean {
    return this.selectedValue === value;
  }

  // Метод интерфейса ControlValueAccessor
  // tslint:disable-next-line:no-empty
  public registerOnTouched(fn: any): void {
  }

  // Колбэк на value change
  // tslint:disable-next-line:no-empty
  public propagateChange = (fn: any): void => {
  };

  ngOnInit() {
  }
}
