import { Injectable } from '@angular/core';

// RxJs
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

// Interface
import { IUploadProgress } from '@app/modules/shared/interfaces/upload-progress.interface';

@Injectable()
export class UploadProgressService {
  private readonly uploadProgressOptions = new BehaviorSubject<IUploadProgress>(null);
  private readonly uploadProgressOptionsObservable = this.uploadProgressOptions.asObservable();
  private readonly hideEvent = new Subject<null>();

  public show(options: IUploadProgress): void {
    this.uploadProgressOptions.next(options)
  }

  public upload(options: any): void {
    this.uploadProgressOptions.next(options)
  }

  // Скрыть окно оповещения
  public hide(): void {
    this.uploadProgressOptions.next(null);
    this.hideEvent.next(null);
  }

  public onOpen(then: (options: IUploadProgress) => void): Subscription {
    return this.uploadProgressOptionsObservable.subscribe(then);
  }

  public onUpload(then: (opt: any) => void): Subscription {
    return this.uploadProgressOptions.subscribe(then);
  }

  public onHide(then: () => void): Subscription {
    return this.hideEvent
      .asObservable()
      .subscribe(then);
  }
}
