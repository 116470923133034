import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// Modules
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from "./modules/shared/shared.module";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { CoreModule } from "@app/modules/core/core.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

// Interceptors
import { ApplyTokenInterceptor } from '@app/modules/shared/interceptors/apply-token.interceptor';
import { RefreshTokenInterceptor } from '@app/modules/shared/interceptors/refresh-token.interceptor';
import { LoaderInterceptor } from '@app/modules/shared/interceptors/loader.intercdptor';

// Components
import { AppComponent } from './app.component';
import { NotificationService, UploadProgressService } from '@app/modules/shared/services';
import { NotificationComponent, UploadProgressComponent } from '@app/modules/shared/components';

@NgModule({
  declarations: [
    AppComponent,
    UploadProgressComponent,
    NotificationComponent
  ],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    NgbModule,
    ReactiveFormsModule
  ],
  providers: [
    UploadProgressService,
    NotificationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApplyTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
