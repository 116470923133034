export class ChannelStatModel {
  channelName: string;
  channelId: string;
  channelNiche: string;

  active: number;
  wait: number;
  processing: number;
  deleted: number;
  error: number;
  created: number;
}
