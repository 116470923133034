import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'app-upload-csv',
  templateUrl: 'upload-csv.component.html',
  styleUrls: ['upload-csv.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadCsvComponent),
      multi: true
    }
  ]
})

export class UploadCsvComponent {
  @Input() isFileUploaded = false;

  public imagePath;
  public fileURL: any;
  public videoTitle: string;
  public message: string;

  // Установить выбранное значение
  private setSelectedValue(v: any): void {
    this.propagateChange(v);
  }

  public preview(files) {
    this.fileURL = null;
    if (files.length === 0) {
      return;
    }

    const mimeType = files[ 0 ].type;
    if (mimeType.match(/csv\/*/) == null) {
      console.warn("Only .csv are supported.");
      return;
    }

    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[ 0 ]);
    reader.onload = (_event) => {
      this.videoTitle = files[0].name;
      this.fileURL = reader.result;
    }

    this.setSelectedValue(files);
  }


  /** Связываем через ngModel */
  public writeValue(value: any): void {
    if (!value) {
      return;
    }
    this.setSelectedValue(value);
  }

  /** Выбор значения селекта */
  public selectValue(value: any): void {
    this.setSelectedValue(value);
  }

  // Метод интерфейса ControlValueAccessor
  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  // Метод интерфейса ControlValueAccessor
  // tslint:disable-next-line:no-empty
  public registerOnTouched(fn: any): void {
  }

  // Колбэк на value change
  // tslint:disable-next-line:no-empty
  public propagateChange = (fn: any): void => {
  };
}
