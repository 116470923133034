import { Injectable } from '@angular/core';
import {plainToClass} from "class-transformer";

// RxJs
import { Observable, pipe } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { HttpService } from '@app/modules/core';

// Model
import { SitesModel } from '@app/modules/shared/models/sites.model';
import { SiteCategoriesModel } from '@app/modules/shared/models/site-categories.model';
import { WebmasterSiteInfoModel } from '@app/modules/shared/models/webmaster-site-info.model';

@Injectable()
export class SitesService {
  constructor(
    private readonly httpService: HttpService,
  ) {
  }

  getAllSites(): Observable<any> {
    return this.httpService.send(
      {
        url: 'sites',
        method: 'GET'
      })
      .pipe(
        map(res => plainToClass(SitesModel, res.data))
      );
  }

  getWebmastersSitesInfo(siteGuid): Observable<any> {
    return this.httpService.send(
      {
        url: `users/by-site/${siteGuid}`,
        method: 'GET'
      })
      .pipe(
        map(res => plainToClass(WebmasterSiteInfoModel, res.data))
      );
  }

  getSiteCategories(guid): Observable<any> {
    return this.httpService.send(
      {
        url: `sites/${guid}/categories`,
        method: 'GET'
      })
      .pipe(
        map(res => plainToClass(SiteCategoriesModel, res.data))
      );
  }
}
