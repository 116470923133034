import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

// RxJs
import { Observable } from 'rxjs';

// Services
import { AuthService } from '@app/modules/core/services/auth.service';

@Injectable()
export class ApplyTokenInterceptor implements HttpInterceptor {

  constructor(
    private readonly auth: AuthService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getToken()}`
      }
    });

    return next.handle(request);
  }
}
