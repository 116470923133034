<div class="upload-csv">
  <div class="upload-csv__preview" *ngIf="fileURL">
    <span>
      {{ isFileUploaded ? ('Filename: ' + videoTitle + ' was succesfully uploaded') : ('Filename: ' + videoTitle ) }}
    </span>
  </div>

  <div class="upload-csv__control">
    <label class="upload-csv__button">
      <input type="file"
             #file
             (change)="preview(file.files)"
             accept='text/csv'
      />
      <span class="upload-csv__button-text">
        {{ fileURL ? 'Reload csv' : 'Load csv' }}
      </span>
    </label>
  </div>
</div>
