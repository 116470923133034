import { Injectable } from '@angular/core';

// RxJs
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

// Interfaces
import { INotificationOptions } from '../interfaces/notification-options.interface';

@Injectable()
export class NotificationService {
  private readonly notificationOptions = new BehaviorSubject<INotificationOptions>(null);
  private readonly notificationOptionsObservable = this.notificationOptions.asObservable();
  private readonly hideEvent = new Subject<null>();

  /**
   * Отобразить окно оповещения
   * @param options Параметры отображения окна оповещения
   */
  public show(options: INotificationOptions): void {
    if (this.notificationOptions.value) {
      const hideAnimationDelay = 200;

      this.hide();

      setTimeout(
        () => {
          this.notificationOptions.next(options);
        },
        hideAnimationDelay
      );
    } else {
      this.notificationOptions.next(options);
    }

    if (!options.timer) {
      options.timer = 3000;
    }

    setTimeout(
      () => {
        this.hide();
      },
      options.timer
    );
  }

  // Скрыть окно оповещения
  public hide(): void {
    this.notificationOptions.next(null);
    this.hideEvent.next(null);
  }

  // Событие при переключении оповещения
  public onOpen(then: (options: INotificationOptions) => void): Subscription {
    return this.notificationOptionsObservable.subscribe(then);
  }

  public onHide(then: () => void): Subscription {
    return this.hideEvent
      .asObservable()
      .subscribe(then);
  }
}
