import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// RxJs
import { finalize } from 'rxjs/operators';

// Interfaces
import { SignUpForm } from '@app/modules/shared/interfaces/sign-up-form';
import { SignUpBtForm } from '@app/modules/shared/interfaces/sign-up-bt-form';

// Services
import { ConfigStorageService } from '@app/modules/core/services/config-storage.service';
import { RegistrationService } from '@app/modules/core/services/registration.service';
import { LoginService } from '@app/modules/core/services/login.service';
import { AuthService } from '@app/modules/core/services/auth.service';
import { UserService } from '@app/modules/core';

// Constants
import { MIN_PAYOUT } from '@app/modules/shared/constants/min-payout';
import { PAYMENT_METHODS } from '@app/modules/shared/constants/payment-methods';

@Component({
  selector: 'app-register',
  templateUrl: 'register.component.html',
  styleUrls: ['register.component.scss']
})

export class RegisterComponent implements OnInit {
  private redirectUrl: string;

  public isAdmin: boolean;
  public isLoading = false;

  public form: FormGroup;
  public formBt: FormGroup;
  public error: string;

  public captchaKey: string;
  public captchaKeyBt: string;

  MIN_PAYOUT = MIN_PAYOUT;
  PAYMENT_METHODS = PAYMENT_METHODS;

  isPassword = true;

  public SOURCES = {
    cp: 'cp',
    bt: 'bt'
  };

  public currentSource = 'cp';

  constructor(
    private readonly fb: FormBuilder,
    private readonly activeModal: NgbActiveModal,
    private readonly route: Router,
    private readonly regService: RegistrationService,
    private readonly loginService: LoginService,
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly configStorageService: ConfigStorageService,
  ) {
    this.redirectUrl = this.configStorageService.get('redirectUrl');
    this.captchaKey = this.configStorageService.get('recaptchaKey');
    this.captchaKeyBt = this.configStorageService.get('recaptchaKeyBT');
  }

  private initForm(): void {
    const fields = {
      username: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      password_hash: ['', Validators.required]
    };

    if (!this.isAdmin) {
      fields['recaptchaToken'] = ['', Validators.required]
    }

    this.form = this.fb.group(fields);

    this.formBt = this.fb.group({
      login: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      password: ['', Validators.required],
      name: [''],
      skype: [''],
      telegram: [''],
      recaptchaToken: ['', Validators.required],
      paymentType: ['paxum'],
      minPayment: [10000],
      paxumEmail: ['', [Validators.email, Validators.required]],
    });

    this.form.valueChanges.subscribe(() => {
      this.resetError();
    });

    this.formBt.valueChanges.subscribe(() => {
      this.resetError();
    });
  }

  public setCurrentSource(source: string) {
    this.currentSource = source;
  }

  resetError(): void {
    if (!!this.error) {
      this.error = '';
    }
  }

  passwordViewToggle(): void {
    this.isPassword = !this.isPassword;
  }

  onSubmit({ value, valid }: { value: SignUpForm, valid: boolean }): void {
    if (valid) {
      this.isLoading = true;
      const request = this.regService.register(this.form.getRawValue());

      request
        .pipe(
          finalize(() => {this.isLoading = false})
        )
        .subscribe({
          next: () => {
            // window[ 'grecaptcha' ].reset();

            if (!this.isAdmin) {
              this.onRegisterSuccess({ username: value.username, password_hash: value.password_hash });
            } else {
              this.activeModal.close();
            }
          },
          error: (err) => {
            // window[ 'grecaptcha' ].reset();
            // this.form.controls.recaptchaToken.setValue('');
            // this.error = err.error;
            this.error = err ? err : 'Something went wrong';
          }
        });
    }
  }

  onSubmitBt({ value, valid }: { value: SignUpBtForm, valid: boolean }): void {
    if (valid) {
      this.isLoading = true;
      const request = this.regService.registerBT(this.formBt.getRawValue());

      request
        .pipe(
          finalize(() => {this.isLoading = false})
        )
        .subscribe({
          next: () => {
            // window[ 'grecaptcha' ].reset();

            if (!this.isAdmin) {
              this.onRegisterBtSuccess({ username: value.login, password_hash: value.password });
            } else {
              this.activeModal.close();
            }
          },
          error: (err) => {
            // window[ 'grecaptcha' ].reset();
            // this.form.controls.recaptchaToken.setValue('');
            // this.error = err.error;
            this.error = err ? err : 'Something went wrong';
          }
        });
    }
  }

  onRegisterSuccess({ username, password_hash }: { username: string; password_hash: string }): void {
    const request = this.loginService.login({ username, password_hash });

    request
      .subscribe({
        next: (res) => {
          this.authService.auth(res.data);
          this.activeModal.close();
        },
        error: () => {
        }
      });
  }

  onRegisterBtSuccess({ username, password_hash }: { username: string; password_hash: string }): void {
    const request = this.loginService.loginBT({ username, password_hash });

    request
      .subscribe({
        next: (res) => {
          const url = `${this.redirectUrl}?token=${res.data.token}`;

          window.open(url, '_top');
        },
        error: () => {
        }
      });
  }

  navigate(link: string) {
    this.closeModal();
    this.route.navigate([link]);
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }

  ngOnInit(): void {
    this.userService.isAdminSubject.subscribe(value => this.isAdmin = value);
    this.initForm();
  }
}
