import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// RxJs
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// Params
import { HttpServiceParams } from '@app/modules/core/models/http-service-params';

// Services
import { ConfigStorageService } from '@app/modules/core/services/config-storage.service';

@Injectable()
export class HttpService {
  private readonly httpUrl: string;

  private headers = new HttpHeaders({
    'Content-type': 'application/json'
  });

  constructor(
    private readonly configStorageService: ConfigStorageService,
    private httpClient: HttpClient
  ) {
    this.httpUrl = this.configStorageService.get('httpURL');
  }

  send({
         url,
         host,
         queryParams,
         body,
         headers,
         method,
         responseType,
         reportProgress,
         observe
       }: HttpServiceParams): Observable<any> {
    const options = {};

    if (!!body) {
      options[ 'body' ] = body;
    }

    if (!!queryParams) {
      options[ 'params' ] = queryParams;
    }

    if (!!responseType) {
      options[ 'responseType' ] = responseType;
    }

    if (!!reportProgress) {
      options[ 'reportProgress' ] = reportProgress;
    }

    if (!!observe) {
      options[ 'observe' ] = observe;
    }

    options[ 'headers' ] = headers || this.headers;

    const uri = `${host || this.httpUrl}/${url}`;

    const queryParamsMap = {};
    if (!!queryParams) {
      queryParams.keys().forEach(key => {
        queryParamsMap[ key ] = queryParams.get(key);
      });
    }

    return this.httpClient
      .request(method, `${uri}`, options).pipe(
        map(res => {
          return res;
        }),
        catchError((err) => {
          const error =
            (!!err &&
              !!err.error &&
              !!err.error.errors &&
              !!err.error.errors.length &&
              err.error.errors[ 0 ]) ||
            (!!err && err.error);

          return throwError(error);
        })
      )
  }

  multi({
          url,
          host,
          body,
          queryParams,
          responseType,
          method,
          reportProgress,
          observe
        }: HttpServiceParams): Observable<any> {
    const options = {};

    if (!!body) {
      options[ 'body' ] = body;
    }

    if (!!queryParams) {
      options[ 'params' ] = queryParams;
    }

    if (!!responseType) {
      options[ 'responseType' ] = responseType;
    }

    if (!!reportProgress) {
      options[ 'reportProgress' ] = reportProgress;
    }

    if (!!observe) {
      options[ 'observe' ] = observe;
    }

    const uri = `${host || this.httpUrl}/${url}`;

    return this.httpClient
      .request(method, `${uri}`, options).pipe(
        map(res => {
          return res;
        }),
        catchError((err) => {
          const error =
            (!!err &&
              !!err.error &&
              !!err.error.errors &&
              !!err.error.errors.length &&
              err.error.errors[ 0 ]) ||
            (!!err && err.error);

          return throwError(error);
        })
      )
  }
}
