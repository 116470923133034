export class VideoThumbModel {
  active: boolean;
  custom: boolean;
  filename: string;
  guid: string;
  height: number;
  md5: string;
  path: string;
  videoGUID: string;
  videoMD5: string;
  width: number;
}
