import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formatAgo'
})

export class FormatAgoPipe implements PipeTransform {
  public transform(value: number): string {
    return !!value ? moment(value * 1000).fromNow() : '';
  }
}
