import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// Interfaces
import { ContactUsForm } from '@app/modules/shared/interfaces/contact-us-form';

// Services
import { UserService } from '@app/modules/core';
import { RegistrationService } from '@app/modules/core/services/registration.service';
import { LoginService } from '@app/modules/core/services/login.service';
import { AuthService } from '@app/modules/core/services/auth.service';
import { ContactUsService, SelfService } from '@app/modules/shared/services';
import { ConfigStorageService } from '@app/modules/core/services/config-storage.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: 'contact-us.component.html',
  styleUrls: ['contact-us.component.scss']
})

export class ContactUsComponent implements OnInit {
  public form: FormGroup;
  public error: string;

  public captchaKey: string;

  isAuthUser = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly activeModal: NgbActiveModal,
    private readonly route: Router,
    private readonly regService: RegistrationService,
    private readonly loginService: LoginService,
    private readonly authService: AuthService,
    private readonly configStorageService: ConfigStorageService,
    private readonly userService: UserService,
    private readonly contactUsService: ContactUsService,
    private readonly selfService: SelfService
  ) {
    this.captchaKey = this.configStorageService.get('recaptchaKey');
  }

  private getSelfInfo(): void {
    if (this.userService.isLogged()) {
      this.selfService.getUserSelf()
        .subscribe(
          (res) => {
            this.isAuthUser = true;
            this.initForm(res.email);
          },
          () => {
            this.initForm();
          }
        )
    } else {
      this.initForm();
    }
  }

  private initForm(email = ''): void {
    this.form = this.fb.group({
      email: [email, [Validators.email, Validators.required]],
      subject: ['', Validators.required],
      comment: ['', Validators.required],
      recaptchaToken: ['', Validators.required]
    });
  }

  onSubmit({ value, valid }: { value: ContactUsForm, valid: boolean }): void {
    if (valid) {
      this.contactUsService.sendUserRequest(value)
        .subscribe(
          () => {
            this.activeModal.close();
            window['grecaptcha'].reset();
          },
          (err) => {
            window['grecaptcha'].reset();
            this.form.controls.recaptchaToken.setValue('');
            this.error = err.error;
          })
    }
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }

  ngOnInit(): void {
    this.getSelfInfo();
  }
}
