import { Injectable } from '@angular/core';

// Services
import { ConfigResolverService } from './config-resolver.service';
import { UserService } from './user.service';
import { WebsocketService } from './websocket.service';

@Injectable()
export class AppInitializerService {
  constructor(
    private configResolver: ConfigResolverService,
    private userService: UserService,
    private websocketService: WebsocketService,
  ) {
  }

  init() {
    return new Promise((resolve, reject) => {
      this.configResolver
        .load()
        .then(() => {
          this.websocketService
            .connect()
            .then(() => {
              if (this.userService.isLogged()) {
                resolve();
              } else {
                resolve();
              }
            })
            .catch(err => {
              reject(err);
            });
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
